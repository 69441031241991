import {
  AxiosError,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import CONSTANTS from './constants';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IDevice,
  IUser,
} from '@/type';

export { default as CONSTANTS } from './constants';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

// for local testing, baseServerUrl = https://localhost:3000 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function abbreviateName(user?: IUser): string {
  if (!user) {
    return '';
  }
  if (user.firstName && user.lastName) {
    return (user.firstName[0] + user.lastName[0]).toUpperCase();
  }
  return user.username.slice(0, 2).toUpperCase();
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export function getMediaFileApi(
  fileName: string,
  local = false,
  url = false,
): string {
  const params = new URLSearchParams({
    fileName: encodeURIComponent(fileName),
    ...(local && {
      local: '1',
    }),
    ...(url && {
      url: '1',
    }),
  });

  const fileUrl = `${baseUrl}${ROUTE_API}/mediaFile?${
    params.toString()
  }`;
  return fileUrl;
}

export async function profileImageSrc(user?: IUser): Promise<string> {
  // if there is no profile image
  if (!user?.profileImage) {
    return '';
  }

  const { profileImage } = user;

  // if profileImage is stored in server
  if (!profileImage.startsWith('http')) {
    return getMediaFileApi(profileImage, true);
  }

  // if profile image is remote url

  const fbId = new URL(profileImage).searchParams.get('asid');

  // if profile image comes from fb
  if (fbId) {
    const response = await fetch(profileImage);
    // if current fb profile image is present return it
    if (response.status === 200) {
      return profileImage;
    }
    // returns default fb profile image
    return `https://graph.facebook.com/${fbId}/picture?type=normal`;
  }
  // if profileImage is remote url but not from fb
  return profileImage;
}

export function getFileSignedUrl(
  { fileName, method, size, isPublic }: {
    fileName: string;
    method: 'get' | 'put';
    size?: number;
    isPublic?: boolean
  },
): string {
  const params = new URLSearchParams({
    fileName: encodeURIComponent(fileName),
    method,
    ...(size !== undefined && {
      size: size.toString(),
    }),
    ...(isPublic && {
      isPublic: '1',
    }),
  });

  const url = `/mediaFile/file-signed-url?${params.toString()}`;
  return url;
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, showAdditionalError = false): void {
  console.error(e);

  if (e.isAxiosError && e.response?.data) {
    let errorMessages = '';
    let message = e.response.statusText;
    if (typeof e.response.data === 'string') {
      message = e.response.data;
    } else if (e.response.data.message) {
      message = e.response.data.message;
    }

    if (e.response.data.errors && showAdditionalError) {
      const { errors } = e.response.data;
      for (const key of Object.keys(errors)) {
        errorMessages += `, ${key}: `;
        if (errors instanceof Map) {
          errorMessages += errors.get(key);
        } else {
          errorMessages += errors[key];
        }
      }
    }

    Bus.emit(NOTIFICATION.ERROR, {
      message: message + errorMessages,
      permanent: true,
    });
  }
}

export function identifyDeviceType(): IDevice {
  let isMobile = false;
  if (
    /Android/i.test(navigator.userAgent)
    && /Mobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  } else if (/webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else if (window.innerWidth < 768) {
    isMobile = true;
  }

  const isTouchDevice = 'ontouchend' in document;
  const isChrome = /Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent);
  const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome;
  const isFirefox = /Firefox/i.test(navigator.userAgent);
  const isMacintosh = /Macintosh/i.test(navigator.userAgent);
  const isMac = isMacintosh && !isTouchDevice;
  const isWindows = /Windows/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAndroidTablet = /Android/i.test(navigator.userAgent)
      && !/Mobile/i.test(navigator.userAgent);
  const isLinux = /Linux/i.test(navigator.userAgent) && !isAndroid;
  const isIPad = /iPad/i.test(navigator.userAgent) || (isMacintosh && isTouchDevice);
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  return {
    isMobile,
    isTouchDevice,
    isChrome,
    isSafari,
    isFirefox,
    isMac,
    isWindows,
    isAndroid,
    isLinux,
    isIPad,
    isIPhone,
    isAndroidTablet,
  };
}

export function redirectUserAfterLogin(
  user: IUser,
): { route: string } {
  // if user is admin
  if (user.role === 'admin') {
    return {
      route: CONSTANTS.ROUTES.ADMIN_HOME,
    };
  }

  // otherwise go to guest url
  return {
    route: CONSTANTS.ROUTES.GUEST_HOME,
  };
}

export function download(text: string, filename: string): void {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
