/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import {
  defineStore,
} from 'pinia';

// eslint-disable-next-line import/prefer-default-export
export const useBackendStore = defineStore('backend', {
  state: () => ({
    siteIsActive: true,
  }),
  persist: {
    storage: window.sessionStorage,
  },
});
