// eslint-disable-next-line import/prefer-default-export
export const UtilityCommands = {
  install(): void {
    Object.defineProperty(window, 'token', {
      get(): string {
        return window.sessionStorage.getItem('token') || ' ';
      },
    });

    // NOTE: pinia states stored in sessionStorage
    // add here if aother pinia store is added in @/store
    Object.defineProperty(window, 'app', {
      get(): string {
        return JSON.parse(window.sessionStorage.getItem('app') || '{}');
      },
    });
    Object.defineProperty(window, 'auth', {
      get(): string {
        return JSON.parse(window.sessionStorage.getItem('auth') || '{}');
      },
    });
    Object.defineProperty(window, 'backend', {
      get(): string {
        return JSON.parse(window.sessionStorage.getItem('backend') || '{}');
      },
    });
  },
};
